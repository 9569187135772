const LeafIcon = (props) => {
  return (
    <svg
      width="93"
      height="105"
      viewBox="0 0 93 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_343_133)">
        <path
          d="M18.4965 71.7175C18.4965 71.7175 16.3447 63.442 10.5476 64.3214C4.75054 65.2008 1.97548 63.7238 1.97548 63.7238C1.97548 63.7238 0.14111 78.0312 18.4965 71.7175Z"
          fill="white"
        />
        <path
          d="M9.83033 60.1836C9.83033 60.1836 11.6765 53.6556 7.31395 52.1223C2.95145 50.5777 1.62272 48.537 1.62272 48.537C1.62272 48.537 -5.40902 57.7596 9.83033 60.1836Z"
          fill="white"
        />
        <path
          d="M74.5035 71.7175C74.5035 71.7175 76.6553 63.442 82.4524 64.3214C88.2495 65.2008 91.0246 63.7238 91.0246 63.7238C91.0246 63.7238 92.8589 78.0312 74.5035 71.7175Z"
          fill="white"
        />
        <path
          d="M83.1814 60.1836C83.1814 60.1836 81.3353 53.6556 85.6978 52.1223C90.0603 50.589 91.3891 48.537 91.3891 48.537C91.3891 48.537 98.4208 57.7596 83.1814 60.1836Z"
          fill="white"
        />
        <path
          d="M66.778 54.3547C66.778 54.3547 63.5678 57.8723 54.7135 59.4733C49.716 60.3865 47.9757 64.9302 47.4113 68.7184V58.4473C77.1022 58.3684 66.778 38.6943 66.778 38.6943C66.778 38.6943 63.5678 42.212 54.7135 43.8129C49.716 44.7262 47.9757 49.2698 47.4113 53.0581V44.8276C59.6757 44.2188 60.4047 28.0511 56.1128 24.928C51.6797 21.7035 46.1884 11.8947 46.1884 11.8947C46.1884 11.8947 40.6971 21.7035 36.264 24.928C31.9721 28.0511 32.7011 44.2188 44.9655 44.8276V52.889C44.3776 49.1345 42.6137 44.7036 37.6868 43.8129C28.8325 42.2007 25.6223 38.6943 25.6223 38.6943C25.6223 38.6943 15.2981 58.3571 44.9655 58.4473V68.5606C44.3776 64.8062 42.6137 60.3753 37.6868 59.4846C28.8325 57.8723 25.6223 54.3659 25.6223 54.3659C25.6223 54.3659 15.2981 74.0288 44.9655 74.119V84.2322C44.3776 80.4778 42.6137 76.0469 37.6868 75.1562C28.8325 73.544 25.6223 70.0376 25.6223 70.0376C25.6223 70.0376 15.2981 89.7004 44.9655 89.7906V103.85C44.9655 104.493 45.5181 105.023 46.1884 105.023C46.8586 105.023 47.4113 104.493 47.4113 103.85V89.7906C77.1022 89.7117 66.778 70.0376 66.778 70.0376C66.778 70.0376 63.5678 73.5552 54.7135 75.1562C49.716 76.0695 47.9757 80.6131 47.4113 84.4014V74.1302C77.1022 74.0513 66.778 54.3772 66.778 54.3772V54.3547Z"
          fill="white"
        />
        <path
          d="M12.9346 24.229C14.4413 24.229 15.6627 23.0579 15.6627 21.6133C15.6627 20.1687 14.4413 18.9976 12.9346 18.9976C11.428 18.9976 10.2066 20.1687 10.2066 21.6133C10.2066 23.0579 11.428 24.229 12.9346 24.229Z"
          fill="white"
        />
        <path
          d="M77.2315 24.229C78.7382 24.229 79.9595 23.0579 79.9595 21.6133C79.9595 20.1687 78.7382 18.9976 77.2315 18.9976C75.7249 18.9976 74.5035 20.1687 74.5035 21.6133C74.5035 23.0579 75.7249 24.229 77.2315 24.229Z"
          fill="white"
        />
        <path
          d="M46.5176 5.2314C48.0243 5.2314 49.2457 4.06031 49.2457 2.6157C49.2457 1.17109 48.0243 0 46.5176 0C45.011 0 43.7896 1.17109 43.7896 2.6157C43.7896 4.06031 45.011 5.2314 46.5176 5.2314Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_343_133">
          <rect width="93" height="105" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeafIcon;
