const MissionIcon = () => {
    return (
        <svg
            width="33"
            height="40"
            viewBox="0 0 33 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.2675 27.5401C12.8655 27.6406 12.5639 27.7411 12.2624 27.9422C11.8604 27.3391 11.5588 26.6355 11.1568 26.133C10.4532 25.1278 9.64912 24.2232 8.94553 23.2181C7.83991 21.7104 6.93531 20.2028 6.53326 18.2931C5.62866 13.569 8.24194 8.94547 12.765 7.13626C18.6951 4.72399 25.5299 8.74445 26.4345 15.0767C26.8366 17.9915 25.932 20.4038 24.3238 22.7156C23.6202 23.7207 22.7156 24.7258 22.012 25.8314C21.5095 26.535 21.208 27.2386 20.7054 27.9422C20.5044 28.2437 20.3033 28.5452 20.0018 28.6457C17.69 29.5504 15.3783 30.3544 13.0665 31.1585C12.765 31.259 12.564 31.1585 12.4634 30.9575C12.3629 30.656 12.3629 30.3544 12.6645 30.2539C12.8655 30.1534 13.0665 30.0529 13.2675 30.0529C15.2778 29.3493 17.288 28.6457 19.2982 27.8417C19.4992 27.7411 19.7003 27.5401 19.8008 27.3391C20.6049 25.8314 21.61 24.6253 22.6151 23.2181C23.6202 21.9115 24.5248 20.7053 24.9269 19.0971C25.932 15.8808 25.2284 13.0664 23.0172 10.6542C20.5044 7.83985 17.288 6.93524 13.6696 8.04087C10.0512 9.1465 8.04092 11.6593 7.33734 15.3782C6.83479 17.9915 7.73938 20.2028 9.24706 22.213C10.1517 23.4191 11.0563 24.6253 11.9609 25.8314C12.564 26.2335 12.8655 26.8365 13.2675 27.5401Z"
                fill="white"
            />
            <path
                d="M12.7651 37.4908C12.6646 37.3903 12.4636 37.2898 12.4636 37.0887C12.4636 36.8877 12.5641 36.6867 12.6647 36.4857C12.6647 36.3851 12.8657 36.3851 12.9662 36.3851C15.1774 35.5811 17.4892 34.777 19.7005 33.9729C20.203 33.7718 20.404 33.8724 20.5045 34.1739C20.6051 34.4754 20.404 34.777 20.002 34.978C17.6902 35.7821 15.479 36.5862 13.1672 37.4908C13.0667 37.3903 12.9662 37.3903 12.7651 37.4908Z"
                fill="white"
            />
            <path
                d="M12.7651 34.174C12.6646 34.0735 12.4636 33.973 12.4636 33.772C12.4636 33.5709 12.5641 33.3699 12.6647 33.1689C12.6647 33.0684 12.8657 33.0684 12.9662 33.0684C15.1774 32.2643 17.4892 31.4602 19.7005 30.6561C20.203 30.4551 20.404 30.5556 20.5045 30.8571C20.6051 31.1587 20.5045 31.4602 20.002 31.6612C17.6902 32.4653 15.479 33.3699 13.1672 34.174C13.0667 34.174 12.9662 34.174 12.7651 34.174Z"
                fill="white"
            />
            <path
                d="M20.0019 36.9883C19.2983 39.4006 15.3783 40.8078 12.9661 39.5011C15.2778 38.697 17.5896 37.7924 20.0019 36.9883Z"
                fill="white"
            />
            <path
                d="M16.9866 1.80921C16.9866 2.21125 16.9866 2.6133 16.9866 3.01535C16.9866 3.31688 16.7855 3.51791 16.484 3.51791C16.1825 3.51791 15.9814 3.31688 15.9814 3.01535C15.9814 2.21125 15.9814 1.40716 15.9814 0.502558C15.9814 0.201023 16.1825 0 16.484 0C16.7855 0 16.9866 0.201023 16.9866 0.502558C16.9866 0.904604 16.9866 1.40716 16.9866 1.80921Z"
                fill="white"
            />
            <path
                d="M1.8092 15.8809C2.21124 15.8809 2.6133 15.8809 3.01535 15.8809C3.41739 15.8809 3.61842 16.0819 3.61842 16.3834C3.61842 16.685 3.41739 16.886 3.01535 16.886C2.21125 16.886 1.40717 16.886 0.603076 16.886C0.201029 16.886 0 16.685 0 16.3834C0 16.0819 0.201029 15.8809 0.603076 15.8809C1.00512 15.8809 1.40715 15.8809 1.8092 15.8809Z"
                fill="white"
            />
            <path
                d="M31.1587 16.9865C30.7566 16.9865 30.3546 16.9865 29.9525 16.9865C29.651 16.9865 29.45 16.7855 29.45 16.4839C29.45 16.1824 29.651 15.9814 29.9525 15.9814C30.7566 15.9814 31.6612 15.9814 32.4653 15.9814C32.7668 15.9814 32.9679 16.1824 32.9679 16.4839C32.9679 16.7855 32.7668 16.9865 32.4653 16.9865C31.9627 16.9865 31.5607 16.9865 31.1587 16.9865Z"
                fill="white"
            />
            <path
                d="M5.02564 4.42252C5.22666 4.52303 5.32716 4.62354 5.42768 4.72405C6.03075 5.32712 6.53332 5.82968 7.13638 6.43275C7.43792 6.73428 7.43792 6.93531 7.13638 7.23684C6.93536 7.43786 6.63381 7.43786 6.33227 7.23684C5.7292 6.63377 5.12615 6.13121 4.6236 5.52814C4.52308 5.42763 4.42257 5.1261 4.52308 4.92507C4.62359 4.62354 4.92512 4.52303 5.02564 4.42252Z"
                fill="white"
            />
            <path
                d="M26.0325 25.4294C26.2336 25.53 26.3341 25.6305 26.4346 25.731C27.0376 26.2335 27.5402 26.8366 28.1433 27.3392C28.4448 27.6407 28.4448 27.9422 28.2438 28.1433C28.0428 28.3443 27.7412 28.3443 27.4397 28.0427C26.8366 27.4397 26.3341 26.9371 25.731 26.334C25.6305 26.2335 25.53 25.932 25.6305 25.731C25.6305 25.6305 25.8315 25.53 26.0325 25.4294Z"
                fill="white"
            />
            <path
                d="M28.4448 5.02561C28.3443 5.22663 28.2438 5.32714 28.1433 5.42765C27.5402 6.03072 27.0377 6.53328 26.4346 7.13635C26.2336 7.33737 25.932 7.43788 25.6305 7.13635C25.4295 6.93533 25.4295 6.63379 25.6305 6.33226C26.2336 5.72919 26.7361 5.12612 27.3392 4.62356C27.4397 4.52305 27.7412 4.42254 27.9423 4.52305C28.1433 4.62356 28.2438 4.92509 28.4448 5.02561Z"
                fill="white"
            />
            <path
                d="M5.02557 28.3442C4.92506 28.2437 4.72404 28.1432 4.62353 27.9422C4.52302 27.8417 4.62353 27.5401 4.62353 27.4396C5.2266 26.8366 5.82966 26.133 6.53324 25.5299C6.73427 25.3289 7.03578 25.3289 7.23681 25.5299C7.43783 25.7309 7.43783 26.0325 7.23681 26.2335C6.63374 26.8366 6.03068 27.4396 5.42761 28.0427C5.3271 28.2437 5.22659 28.3442 5.02557 28.3442Z"
                fill="white"
            />
        </svg>
    );
};

export default MissionIcon;
