const LeftSliderArrow = () => {
  return (
    <svg
      width="27"
      height="48"
      viewBox="0 0 27 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9637 3.43636L3.40002 24L23.9637 44.5636"
        stroke="#D0D0D0"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LeftSliderArrow;
