const HygieneAndComfortIcon = (props) => {
	return (
		<svg
			width="66"
			height="66"
			viewBox="0 0 66 66"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
            {...props}
		>
			<path
				d="M27.5853 26.8125H38.5853M33.0853 21.3125V32.3125M10.2273 63.9375L5.15079 57.5905C3.54585 55.5784 2.67305 53.0803 2.67579 50.5065V37.4688C2.67578 36.4678 3.07265 35.5077 3.7794 34.7989C4.48614 34.0901 5.4451 33.6904 6.44604 33.6875C7.44866 33.6882 8.41002 34.0868 9.11898 34.7958C9.82794 35.5048 10.2266 36.4661 10.2273 37.4688V47.2368M46.8353 29.5625C46.8353 33.2092 45.3866 36.7066 42.808 39.2852C40.2294 41.8638 36.732 43.3125 33.0853 43.3125C29.4386 43.3125 25.9412 41.8638 23.3626 39.2852C20.7839 36.7066 19.3353 33.2092 19.3353 29.5625C19.3353 19.25 33.0853 2.0625 33.0853 2.0625C33.0853 2.0625 46.8353 19.25 46.8353 29.5625Z"
				stroke="white"
				stroke-width="3"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M21.571 63.5938V56.0312C21.5702 53.7897 20.9062 51.5986 19.6625 49.7337L16.775 45.419C16.5293 45.0093 16.2029 44.6537 15.8157 44.3738C15.4285 44.094 14.9885 43.8956 14.5224 43.7907C14.0562 43.6858 13.5737 43.6766 13.1039 43.7636C12.6341 43.8506 12.1869 44.0321 11.7893 44.297C11.0742 44.7748 10.5578 45.4971 10.3371 46.3283C10.1164 47.1596 10.2064 48.0428 10.5903 48.8125L14.0003 54.4857M55.7728 63.9375L60.8493 57.5905C62.4553 55.5775 63.327 53.0805 63.3243 50.5065V37.4688C63.3243 36.4659 62.9259 35.5041 62.2168 34.795C61.5077 34.0859 60.5459 33.6875 59.543 33.6875C58.5404 33.6882 57.5791 34.0868 56.8701 34.7958C56.1611 35.5048 55.7625 36.4661 55.7618 37.4688V47.2368"
				stroke="white"
				stroke-width="3"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M44.4287 63.5937V56.0312C44.4287 53.7899 45.0942 51.5982 46.3372 49.7337L49.2247 45.4189C49.4708 45.0095 49.7973 44.6542 50.1845 44.3746C50.5717 44.0949 51.0117 43.8967 51.4777 43.7918C51.9437 43.6869 52.4261 43.6776 52.8958 43.7644C53.3655 43.8513 53.8127 44.0324 54.2105 44.2969C54.9251 44.7751 55.4411 45.4974 55.6618 46.3285C55.8824 47.1596 55.7928 48.0427 55.4095 48.8124L51.9995 54.4857"
				stroke="white"
				stroke-width="3"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default HygieneAndComfortIcon;
