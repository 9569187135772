const VisionIcon = (props) => {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M38.8519 1.14085C38.2511 1.14085 37.5501 1.14085 36.9492 1.14085C32.8434 1.34114 28.9378 2.24242 25.2325 4.04499C20.7261 6.14798 16.9207 9.15225 13.9164 13.158C11.8135 15.8618 10.3113 18.8661 9.20975 22.0706C9.1096 22.2709 9.20975 22.3711 9.30989 22.4712C10.2112 23.3725 11.1125 24.2738 12.0137 25.175C12.1139 25.2752 12.214 25.4755 12.214 25.6758C12.214 25.876 12.1139 26.0763 11.9136 26.1765C11.7133 26.2766 11.4129 26.2766 11.3127 26.0763C10.812 25.5756 10.4115 25.175 9.91074 24.6743C9.30989 24.0735 8.80918 23.4726 8.20832 22.9719C8.00804 22.7716 7.90789 22.4712 8.00804 22.1708C9.30989 18.2652 11.2126 14.6601 13.9164 11.4555C16.6203 8.15083 19.925 5.54712 23.6303 3.64442C27.0351 1.84185 30.7404 0.740284 34.5458 0.239571C36.1481 0.0392864 37.7504 -0.0608561 39.3526 0.0392864C39.8533 0.0392864 39.9535 0.239571 40.0536 0.740284C40.2539 7.24954 38.6516 13.2581 35.1466 18.8661C31.6417 24.3739 26.8348 28.4798 20.8263 31.0835C19.925 31.484 18.9236 31.7845 17.9221 32.185C17.6217 32.2852 17.4214 32.2852 17.2211 31.9847C16.2197 30.9833 15.1182 29.8817 14.1167 28.8803C13.9164 28.68 13.8163 28.3796 14.0166 28.0792C14.2169 27.7788 14.6174 27.7788 14.9179 28.0792C15.8191 28.9805 16.7204 29.8817 17.6217 30.783C17.822 30.9833 17.9221 30.9833 18.1224 30.8832C22.5287 29.381 26.4343 26.9776 29.739 23.7731C33.6445 19.9676 36.2482 15.4612 37.7503 10.354C38.5515 7.54997 38.9521 4.64584 38.9521 1.64157C38.9521 1.44128 38.8519 1.34114 38.8519 1.14085Z"
                fill="white"
            />
            <path
                d="M33.3438 12.8575C33.3438 16.2624 30.64 18.9662 27.2352 18.9662C23.8303 18.9662 21.1265 16.2624 21.1265 12.8575C21.1265 9.55283 23.8303 6.74884 27.135 6.74884C30.64 6.74884 33.3438 9.45269 33.3438 12.8575ZM32.1421 12.9577C32.1421 10.1537 29.939 7.95055 27.135 7.95055C24.331 7.95055 22.1279 10.1537 22.1279 12.9577C22.1279 15.7617 24.331 17.9648 27.135 17.9648C29.939 17.8647 32.1421 15.6615 32.1421 12.9577Z"
                fill="white"
            />
            <path
                d="M18.0222 38.494C18.7232 38.0934 19.4242 37.5927 20.025 37.092C22.0279 35.2894 23.1294 33.0863 23.6302 30.4826C23.7303 30.082 23.9306 29.8817 24.231 29.8817C24.6316 29.8817 24.8319 30.1821 24.8319 30.5827C24.7317 31.0834 24.6316 31.6843 24.4313 32.185C23.3297 35.5898 21.3269 38.0934 18.2225 39.7958C17.5215 40.1964 17.221 39.9961 17.1209 39.2951C16.7203 37.1921 16.3198 34.989 15.9192 32.886C15.9192 32.6857 15.9192 32.4854 15.9192 32.3853C16.0193 32.185 16.2196 32.0848 16.52 32.0848C16.7203 32.0848 16.9206 32.2851 17.0208 32.4854C17.1209 33.0863 17.221 33.6871 17.4213 34.3881C17.5215 35.7901 17.8219 37.092 18.0222 38.494Z"
                fill="white"
            />
            <path
                d="M1.49871 21.9704C2.09956 22.0706 2.70042 22.1707 3.20113 22.2709C4.60312 22.5713 6.00512 22.7716 7.30697 23.072C7.40711 23.072 7.40711 23.072 7.50726 23.072C7.80768 23.1721 8.00797 23.4726 7.90783 23.773C7.80768 24.0734 7.6074 24.2737 7.20683 24.1736C6.40569 23.9733 5.50441 23.8731 4.70327 23.6728C3.40141 23.3724 2.09956 23.1721 0.697567 22.8717C0.597425 22.8717 0.597425 22.8717 0.497282 22.8717C-0.00343033 22.7716 -0.103573 22.4711 0.0967121 21.9704C0.697567 20.8689 1.39856 19.7673 2.29985 18.866C4.20255 16.8632 6.50583 15.7616 9.10954 15.1607C9.20968 15.1607 9.30982 15.1607 9.30982 15.0606C9.61025 14.9605 9.91067 15.1607 10.0108 15.4612C10.111 15.7616 9.91067 16.062 9.51011 16.1622C8.90925 16.3624 8.20825 16.4626 7.6074 16.6629C5.00369 17.5642 3.00084 19.1664 1.49871 21.4697C1.59885 21.7701 1.59885 21.8703 1.49871 21.9704Z"
                fill="white"
            />
            <path
                d="M8.60889 30.8832C8.70903 30.7831 8.80917 30.5828 8.90931 30.4826C10.812 28.5799 12.6146 26.7774 14.5173 24.8747C15.2183 24.1737 15.8191 23.5728 16.5201 22.8718C16.8206 22.5714 17.121 22.5714 17.4214 22.7717C17.6217 22.972 17.6217 23.3725 17.3213 23.6729C15.1181 25.8761 12.915 28.1794 10.6117 30.3825C10.3113 30.6829 10.0109 31.0835 9.61031 31.3839C9.41003 31.5842 9.20974 31.5842 9.00946 31.4841C8.70903 31.3839 8.60889 31.1836 8.60889 30.8832Z"
                fill="white"
            />
            <path
                d="M6.00514 33.4868C6.20543 33.587 6.40571 33.6871 6.50585 33.8874C6.606 34.0877 6.50585 34.288 6.40571 34.4882C6.30557 34.5884 6.10528 34.7887 6.00514 34.989C4.80343 36.1907 3.60172 37.4925 2.29987 38.6942C2.09958 38.7944 1.99944 38.8945 1.79916 38.8945C1.59887 38.8945 1.39859 38.7944 1.29845 38.5941C1.1983 38.2937 1.1983 38.0934 1.39859 37.8931C2.19973 37.0919 3.00087 36.2908 3.80201 35.4897C4.40286 34.8888 4.90357 34.3881 5.50443 33.7872C5.70471 33.6871 5.80486 33.587 6.00514 33.4868Z"
                fill="white"
            />
            <path
                d="M5.10385 29.381C5.60456 29.381 5.90499 29.8817 5.7047 30.1821C5.60456 30.2823 5.60456 30.3824 5.50442 30.4825C4.20256 31.7844 2.90071 33.0863 1.59886 34.3881L1.49872 34.4882C1.19829 34.6885 0.897862 34.7887 0.697577 34.4882C0.497292 34.288 0.497292 33.8874 0.697577 33.6871C1.699 32.6857 2.70043 31.6843 3.70185 30.6828C4.10242 30.2823 4.40285 29.9818 4.80342 29.6814C4.90356 29.4811 5.10385 29.381 5.10385 29.381Z"
                fill="white"
            />
            <path
                d="M10.7117 34.9891C10.7117 35.0892 10.6116 35.1894 10.5115 35.3896C9.10946 36.7916 7.80761 38.0935 6.40561 39.4955C6.10519 39.7959 5.80476 39.7959 5.50433 39.4955C5.30405 39.2952 5.30405 38.8946 5.60447 38.6944C7.00647 37.2924 8.30832 35.9905 9.71032 34.5885C9.9106 34.3882 10.1109 34.2881 10.4113 34.3882C10.6116 34.4884 10.7117 34.5885 10.7117 34.9891Z"
                fill="white"
            />
        </svg>
    );
};

export default VisionIcon;
