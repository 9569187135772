const TriangleIcon = (props) => {
  return (
    <svg
      width="113"
      height="133"
      viewBox="0 0 113 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_343_43)">
        <path
          d="M18.4223 97.0589C18.4223 97.0589 5.97818 82.4393 19.9272 69.7956L20.6101 71.2603C20.6101 71.2603 21.7806 67.7091 24.498 65.1942L24.6791 66.9629C24.6791 66.9629 27.8285 62.8589 30.727 61.6843L30.1696 63.7847L37.6945 58.672L37.1371 60.7723L43.9793 57.4145L43.7145 58.4233L51.4067 56.3505L49.8878 62.0713L51.6715 61.4633C51.6715 61.4633 51.3788 66.6451 50.6682 68.4967L51.992 67.9854C51.992 67.9854 50.2501 73.3193 49.4001 74.9222L51.9781 73.3469C51.9781 73.3469 49.6788 80.3665 48.2992 81.928L50.6403 80.8225C50.6403 80.8225 49.205 85.8109 46.5155 87.7869C46.5155 87.7869 42.2513 102.351 22.0733 99.3389C22.0733 99.3389 18.2968 106.911 18.3247 109.703C18.3247 109.703 16.5689 110.214 16.6386 108.721C16.7082 107.243 29.8491 74.4247 42.9481 65.4153C42.9481 65.4153 34.6009 67.9578 18.4362 97.0727L18.4223 97.0589Z"
          fill="white"
        />
        <path
          d="M70.4839 61.6291C70.4839 61.6291 88.2791 54.2225 96.2639 71.2189L94.6474 71.4262C94.6474 71.4262 97.7271 73.5956 99.3157 76.912L97.5599 76.5527C97.5599 76.5527 100.57 80.7534 100.835 83.8625L98.9813 82.7018L101.643 91.352L99.7895 90.1912L100.974 97.6669L100.082 97.1142L99.7756 105.004L94.7311 101.854L94.7868 103.719C94.7868 103.719 89.8956 101.895 88.3209 100.665L88.4184 102.075C88.4184 102.075 83.8059 98.8276 82.5239 97.5425L83.2624 100.458C83.2624 100.458 77.2007 96.1883 76.1137 94.4058L76.476 96.9622C76.476 96.9622 72.1004 94.1156 71.0135 90.9789C71.0135 90.9789 58.2768 82.5774 67.2231 64.3927C67.2231 64.3927 61.0777 58.5614 58.3743 57.7462C58.3743 57.7462 58.4161 55.936 59.8236 56.4472C61.231 56.9585 88.8644 79.2196 93.6163 94.3091C93.6163 94.3091 93.672 85.6451 70.4979 61.6291H70.4839Z"
          fill="white"
        />
        <path
          d="M77.2146 119.707C77.2146 119.707 70.5118 137.643 52.5355 131.825L53.4831 130.513C53.4831 130.513 49.7903 131.259 46.2507 130.14L47.7139 129.117C47.7139 129.117 42.5579 128.426 40.1053 126.492L42.2235 125.939L34.0296 121.959L36.1478 121.406L29.8351 117.15L30.8524 116.888L25.2505 111.264L31.0196 109.758L29.6122 108.514C29.6122 108.514 34.3083 106.22 36.2732 105.916L35.1723 105.018C35.1723 105.018 40.7185 103.899 42.544 103.844L39.8824 102.393C39.8824 102.393 47.1705 100.928 49.2329 101.356L47.1147 99.8778C47.1147 99.8778 52.201 98.6618 55.2528 100.002C55.2528 100.002 70.1495 96.52 77.4236 115.423C77.4236 115.423 85.9241 114.953 88.3627 113.572C88.3627 113.572 89.6726 114.843 88.3348 115.506C86.9971 116.169 51.7272 120.964 37.3601 114.097C37.3601 114.097 43.7006 120.052 77.2285 119.679L77.2146 119.707Z"
          fill="white"
        />
        <path
          d="M44.4113 129.684C39.1159 129.338 36.5379 126.353 36.5379 126.353H16.2066C12.7228 126.353 9.62919 124.557 7.9291 121.531C6.22902 118.519 6.31263 114.953 8.13813 112.024L16.332 98.8829C14.9803 96.9345 13.6843 93.6872 12.9458 91.6836L2.43866 108.528C-0.682809 113.544 -0.82216 119.61 2.07635 124.764C4.97486 129.919 10.2423 132.986 16.1926 132.986H47.9926C45.0384 131.95 42.7948 130.72 42.7948 130.72L44.3974 129.684H44.4113Z"
          fill="white"
        />
        <path
          d="M39.0463 56.3505L43.9932 54.5127L48.4525 47.3687C50.1944 44.5774 53.2043 42.9192 56.507 42.9192C59.8096 42.9192 62.8196 44.5774 64.5615 47.3687L71.543 58.5614C73.898 58.0363 76.6851 57.9396 79.068 58.0087L70.2609 43.8727C67.2928 39.1193 62.1507 36.2727 56.507 36.2727C50.8632 36.2727 45.7212 39.1193 42.753 43.8727L35.0747 56.1985L39.6037 54.6094L39.0463 56.3367V56.3505Z"
          fill="white"
        />
        <path
          d="M110.547 108.542L102.158 95.0691C102.312 97.9433 102.131 100.099 102.131 100.099L101.657 99.3804L101.155 106.068L104.876 112.024C106.701 114.967 106.785 118.519 105.085 121.531C103.385 124.543 100.291 126.353 96.8074 126.353H75.5145C73.3824 129.822 70.9159 131.839 68.3797 132.986H96.8074C102.758 132.986 108.025 129.919 110.924 124.764C113.822 119.61 113.683 113.544 110.561 108.528L110.547 108.542Z"
          fill="white"
        />
        <path
          d="M55.9914 29.5433C55.6291 29.5433 55.3225 29.2531 55.3225 28.88V0.663273C55.3225 0.304 55.6151 0 55.9914 0C56.3676 0 56.6603 0.290182 56.6603 0.663273V28.88C56.6603 29.2393 56.3676 29.5433 55.9914 29.5433Z"
          fill="white"
        />
        <path
          d="M45.0105 32.6109C44.8014 32.6109 44.6063 32.5142 44.467 32.3345L27.6473 9.57598C27.4243 9.28579 27.494 8.87125 27.7866 8.65016C28.0793 8.42907 28.4973 8.49816 28.7203 8.78834L45.5539 31.5469C45.7769 31.8371 45.7072 32.2516 45.4146 32.4727C45.2892 32.5556 45.1498 32.5971 45.0244 32.5971L45.0105 32.6109Z"
          fill="white"
        />
        <path
          d="M67.5575 32.6109C67.4182 32.6109 67.2788 32.5695 67.1674 32.4866C66.8747 32.2655 66.805 31.8509 67.028 31.5607L83.8616 8.80219C84.0846 8.51201 84.5027 8.44292 84.7953 8.66401C85.0879 8.8851 85.1576 9.29965 84.9347 9.58983L68.101 32.3484C67.9756 32.528 67.7666 32.6247 67.5575 32.6247V32.6109Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_343_43">
          <rect width="113" height="133" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TriangleIcon;
