const RabbitIcon = (props) => {
  return (
    <svg
      width="123"
      height="147"
      viewBox="0 0 123 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_343_23)">
        <path
          d="M81.7304 36.623C81.7304 36.623 92.1447 37.8087 98.4977 44.6523C104.851 51.4958 107.429 54.8159 105.002 56.8656C105.002 56.8656 104.531 65.6063 92.5491 67.3341C92.5491 67.3341 97.2507 79.2255 97.2507 84.9002C97.2507 90.5749 95.2959 89.0673 95.2959 89.0673C95.2959 89.0673 97.1833 96.4698 95.852 99.7053C94.5208 102.941 93.2737 100.891 93.2737 100.891C93.2737 100.891 89.6675 113.104 88.1845 122.082C86.7016 131.06 86.0612 136.328 88.2688 137.277C90.4764 138.225 96.88 138.141 96.4081 144.917C96.4081 144.917 94.7567 147.508 86.5331 146.644C78.3095 145.78 75.411 135.854 74.619 129.248C74.619 129.248 69.2939 136.972 65.6876 137.904C65.6876 137.904 75.6301 137.429 78.3769 145.221C78.3769 145.221 80.5676 147.695 60.7501 146.814C40.9326 145.933 33.956 148.05 30.9733 140.258C30.9733 140.258 21.0308 141.986 16.1607 136.244C11.3074 130.501 11.0715 116.153 14.4418 118.915C17.8122 121.676 20.0871 125.064 20.0871 125.064C20.0871 125.064 14.8463 113.494 21.4184 101.602C28.0074 89.711 39.1295 74.8213 55.1048 69.0789C71.0801 63.3364 71.8722 47.8877 65.5191 42.7042C59.166 37.5038 47.3362 29.5593 46.0049 21.4453C44.6736 13.3313 46.8644 8.84239 46.8644 8.84239C46.8644 8.84239 49.9988 17.4307 58.0707 20.412C66.1426 23.4103 72.2429 31.2871 72.1755 35.6913C72.1755 35.6913 72.0238 26.002 68.6535 20.3273C65.2832 14.6526 66.6145 3.23545 68.4176 0.0169678C68.4176 0.0169678 89.0271 9.31669 81.7304 36.6399V36.623Z"
          fill="white"
        />
        <path
          d="M43.0053 48.9209C44.1344 45.9566 42.6515 42.6364 39.7024 41.5015C37.7813 40.7731 35.7254 41.1458 34.2088 42.2976C33.8381 40.4174 32.5573 38.7573 30.6362 38.0289C27.6872 36.894 24.3843 38.3846 23.2552 41.349C22.9688 42.1283 22.8508 42.9244 22.8845 43.7036C22.8845 43.7036 22.9856 49.3614 28.9848 55.9508V55.9847C28.9848 55.9847 28.9848 55.9847 29.0016 55.9847C29.0016 55.9847 29.0016 56.0016 29.0185 56.0185V55.9847C37.8656 55.0869 41.6909 50.9367 41.6909 50.9367C42.2302 50.3777 42.6852 49.7171 42.9716 48.9379L43.0053 48.9209Z"
          fill="white"
        />
        <path
          d="M8.12251 63.9123C7.29678 61.7441 8.37528 59.3048 10.5491 58.4748C11.9478 57.9327 13.4645 58.2037 14.5767 59.0507C14.8463 57.6786 15.79 56.459 17.1887 55.9169C19.3457 55.0869 21.7723 56.171 22.5981 58.3562C22.8171 58.9321 22.9014 59.5081 22.8677 60.084C22.8677 60.084 22.8003 64.2342 18.3851 69.0619C18.3851 69.0619 18.3683 69.0788 18.3514 69.0788C18.3514 69.0788 18.3514 69.0788 18.3346 69.0958C11.8467 68.4182 9.04935 65.3691 9.04935 65.3691C8.64491 64.9626 8.32473 64.4713 8.10566 63.8954L8.12251 63.9123Z"
          fill="white"
        />
        <path
          d="M96.7957 131.467C96.7957 131.467 94.2343 115.73 109.03 110.004C109.03 110.004 115.012 121.506 96.7957 131.467Z"
          fill="white"
        />
        <path
          d="M100.301 132.974C100.301 132.974 109.384 125.589 118.433 133.025C118.433 133.025 113.293 141.071 100.301 132.974Z"
          fill="white"
        />
        <path
          d="M4.54994 85.3914C4.54994 86.6619 3.52199 87.6782 2.27497 87.6782C1.02795 87.6782 0 86.6449 0 85.3914C0 84.1379 1.02795 83.1046 2.27497 83.1046C3.52199 83.1046 4.54994 84.1379 4.54994 85.3914Z"
          fill="white"
        />
        <path
          d="M96.6272 7.84291C96.6272 9.11336 95.5993 10.1297 94.3522 10.1297C93.1052 10.1297 92.0773 9.09642 92.0773 7.84291C92.0773 6.58939 93.1052 5.55609 94.3522 5.55609C95.5993 5.55609 96.6272 6.58939 96.6272 7.84291Z"
          fill="white"
        />
        <path
          d="M123 89.9651C123 91.2356 121.972 92.2519 120.725 92.2519C119.478 92.2519 118.45 91.2186 118.45 89.9651C118.45 88.7116 119.478 87.6783 120.725 87.6783C121.972 87.6783 123 88.7116 123 89.9651Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_343_23">
          <rect width="123" height="147" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RabbitIcon;
