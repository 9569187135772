import { createSelector } from "reselect";

const selectCategoryReducer = (state) => state.categories;

export const selectCategories = createSelector(
    [selectCategoryReducer],
    (categoriesSlice) => categoriesSlice.categories,
);

export const selectCategoriesMap = createSelector(
    [selectCategories],
    (categories) =>
        categories.reduce((acc, category) => {
            const { title, items } = category;
            acc[title.toLowerCase()] = items;
            return acc;
        }, {}),
);

export const selectProducts = createSelector(
    [selectCategoriesMap],
    (categories) => categories['products']
);

export const selectFeaturedProducts = createSelector(
    [selectCategoriesMap],
    (categories) =>  categories['featured']
);

export const selectHampers = createSelector(
    [selectCategoriesMap],
    (categories) =>  categories['hampers']
);
