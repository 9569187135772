const HealthAssesmentRobotIcon = (props) => {
    return (
        <svg
            width="62"
            height="62"
            viewBox="0 0 62 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M39.263 13.356c.03.036.058.075.083.114l.001.002.003.005.009.015.032.053c.183.305.36.614.531.927.34.622.791 1.5 1.243 2.538C42.06 19.06 43 21.827 43 24.463c0 2.983-2.015 4.637-3.787 5.491a11.71 11.71 0 0 1-2.776.915A7.972 7.972 0 0 1 31 32.998a7.984 7.984 0 0 1-6.257-3.013l-.046-.004a8.008 8.008 0 0 1-.649-.086 7.899 7.899 0 0 1-1.97-.606c-.73-.34-1.506-.862-2.1-1.663-.603-.813-.978-1.859-.978-3.164 0-2.467 1.202-5.27 2.306-7.352a36.347 36.347 0 0 1 2.206-3.619l.032-.045.012-.019c.126-.191.264-.374.413-.548a7.37 7.37 0 0 1 1.32-1.195C26.503 10.821 28.373 10 31.055 10c2.675 0 4.712.817 6.084 1.643.685.413 1.204.827 1.557 1.144.193.174.376.357.55.55l.01.012.005.005.002.002Zm-14.026 1.156a.975.975 0 0 1-.048.074l-.002.002-.008.01-.033.048a34.264 34.264 0 0 0-2.073 3.401C21.987 20.095 21 22.523 21 24.463c0 .899.25 1.522.584 1.973.344.462.819.8 1.339 1.042.247.115.497.206.735.277a24.264 24.264 0 0 1-.429-2.6c-.113-1.074-.146-2.265.056-3.318.199-1.032.668-2.141 1.742-2.718 1.429-.767 3.847-1.793 5.979-2.5 1.063-.353 2.1-.642 2.929-.774.407-.064.825-.1 1.193-.063.288.03.885.139 1.215.68 2.68 3.869 2.72 7.414 2.041 10.003-.196.748-.45 1.412-.712 1.976.228-.086.452-.183.672-.288 1.45-.7 2.656-1.814 2.656-3.69 0-2.222-.81-4.686-1.668-6.654a30.269 30.269 0 0 0-1.546-3.051l-.094-.159-.024-.025-.011-.012a5.49 5.49 0 0 0-.298-.287 8.263 8.263 0 0 0-1.252-.918C34.99 12.683 33.305 12 31.055 12c-2.244 0-3.712.679-4.606 1.315a5.37 5.37 0 0 0-.961.867 3.49 3.49 0 0 0-.246.322l-.005.008Zm.505 13.382a22.73 22.73 0 0 1-.524-2.947c-.105-.993-.118-1.96.03-2.733.153-.792.43-1.175.724-1.333 1.307-.702 3.616-1.685 5.663-2.364 1.026-.34 1.942-.59 2.612-.696.253-.04.439-.055.565-.054 2.198 3.268 2.175 6.137 1.636 8.191a9.889 9.889 0 0 1-1.053 2.517c-.171.29-.36.568-.565.834l-.007.009-.021.026-.004.004-.001.001a.998.998 0 0 0-.224.471 6 6 0 0 1-8.831-1.926ZM34.98 17.78a.128.128 0 0 1-.026-.006.09.09 0 0 1 .026.006Z"
                fill="#fff"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.914 33.855c-.212-.422-.473-.943-.914-.842-5.404 1.23-11 4.782-11 8.557V47h36v-5.43c0-2.974-3.472-5.808-7.587-7.48l-.014-.027-.005-.01-.033.016c-1.093-.44-2.231-.8-3.361-1.056-.503-.115-1.023.577-1.25 1.01H25a9.48 9.48 0 0 1-.086-.168Zm13.489 1.321c.437.12.872.257 1.301.407.012.342-.014.745-.07 1.157a8.092 8.092 0 0 1-.272 1.26H38a1 1 0 0 0-.894.553l-1 2A.999.999 0 0 0 36 41v2a1 1 0 0 0 1 1h2v-2h-1v-.764L38.618 40h2.764L42 41.236V42h-1v2h2a1 1 0 0 0 1-1v-2a.999.999 0 0 0-.106-.447l-1-2A1 1 0 0 0 42 38h-.566c.123-.529.206-1.066.248-1.608.975.46 1.881.988 2.666 1.56C46.27 39.356 47 40.668 47 41.57V45H15v-3.43c0-.903.73-2.215 2.652-3.617.966-.705 2.119-1.343 3.355-1.871.034.797.162 1.587.381 2.354l.008.028a3 3 0 1 0 1.956-.444 8.09 8.09 0 0 1-.28-1.28 7.013 7.013 0 0 1-.069-1.171 3.99 3.99 0 0 1 .015-.224c.12-.037.24-.073.36-.107l.415.786h14.164l.446-.848ZM23 42.015c.538 0 1-.44 1-1.015 0-.574-.462-1.015-1-1.015s-1 .44-1 1.015c0 .574.462 1.015 1 1.015Z"
                fill="#fff"
            />
            <circle cx="31" cy="31" r="30.5" stroke="#fff" />
        </svg>
    )
}

export default HealthAssesmentRobotIcon